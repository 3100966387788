.login-keypad {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;
}

.key {
  color: #ffffff;
  font-size: 1.4rem;
  font-family: "Majesti Banner",'Champagne Limousines', Montserrat;
  padding: 10px 15px;
  background: #00000099;
  border-radius: 5px;
  cursor: pointer;
  border: solid 1px #ffffff;

  /* Disable text selection on keys */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
