@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply font-montserrat text-lg px-8 py-4 max-w-fit rounded-lg cursor-pointer
    lg:hover:bg-gold lg:hover:text-black 
    lg:group-hover:bg-gold lg:group-hover:text-black 
    transition-all duration-300;
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-color: #C6A97C;
  }

  .shadowed-container {
    position: relative;
  }

  .travel-box{
    
  }
.justify-text{
  text-align: justify;
  margin-bottom:0 !important;
}

.justifytext{
  text-align: justify;
 
}
@media screen and (max-width: 992px) {
  .slide-travel{
    width: 99% !important;
  }

  .exclu{
    gap: 4rem;
  }
}


.exclu-desc{
  text-align: justify;
  font-size: 1.2em;
}

.w-xl{
  width: 40rem !important;

}
.workinprogress {
    height: 90%; 
    text-align:center; 
    display:table;
    width:100%;
}

.workinprogress h1 {
  display:table-cell;
  vertical-align:middle;
   margin:0 auto;
  font-size: 1.5em;
  line-height: 2.6;
  text-transform: uppercase;

}

  .shadowed-container::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

:root {
  /**
   * Gradient for horizontal gold effect
   */
  --golden-gradient-h: linear-gradient(
    90deg,
    rgba(207, 192, 159, 1) 5%,
    rgba(255, 236, 179, 1) 24%,
    rgba(255, 236, 179, 1) 47%,
    rgba(58, 44, 15, 1) 89%,
    rgba(0, 0, 0, 1) 100%
  );

  /**
   * Gradient for vertical gold effect
   */
  --golden-gradient-v: linear-gradient(
    180deg,
    rgba(207, 192, 159, 1) 5%,
    rgba(255, 236, 179, 1) 24%,
    rgba(255, 236, 179, 1) 47%,
    rgb(79, 60, 21) 100%
  );

  /**
   * Text in gold
   */
  --golden-text: #c99e41;
}

/**
font Butler
*/
@font-face {
  font-family: 'Butler';
  font-style: normal;
  font-weight: normal;
  src: local('Butler'), url('./fonts/butler/Butler_Regular.woff') format('woff');
}

@font-face {
  font-family: 'Butler';
  font-style: normal;
  font-weight: 200;
  src: local('Butler UltraLight'),
    url('./fonts/butler/Butler_Ultra_Light.woff') format('woff');
}

@font-face {
  font-family: 'Butler';
  font-style: normal;
  font-weight: 300;
  src: local('Butler Light'),
    url('./fonts/butler/Butler_Light.woff') format('woff');
}

@font-face {
  font-family: 'Butler';
  font-style: normal;
  font-weight: 500;
  src: local('Butler Medium'),
    url('./fonts/butler/Butler_Medium.woff') format('woff');
}

@font-face {
  font-family: 'Butler';
  font-style: normal;
  font-weight: bold;
  src: local('Butler Bold'),
    url('./fonts/butler/Butler_Bold.woff') format('woff');
}

@font-face {
  font-family: 'Butler';
  font-style: normal;
  font-weight: bolder;
  src: local('Butler ExtraBold'),
    url('./fonts/butler/Butler_ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Butler';
  font-style: normal;
  font-weight: 900;
  src: local('Butler Black'),
    url('./fonts/butler/Butler_Black.woff') format('woff');
}

@font-face {
  font-family: 'Canto';
  src: local('Canto Roman'), local('Canto-Roman'),
      url('./fonts/canto-roman/Canto-Roman.woff2') format('woff2'),
      url('./fonts/canto-roman/Canto-Roman.woff') format('woff'),
      url('./fonts/canto-roman/Canto-Roman.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/**
font Montserrat
*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-ThinItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-ExtraLight.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-ExtraLightItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-MediumItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-SemiBoldItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: bold;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-BoldItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-ExtraBoldItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: local('Butler'),
    url('./fonts/montserrat/static/Montserrat-BlackItalic.ttf')
      format('truetype');
}

/**
font Modern line
*/
@font-face {
  font-family: 'Mordernline';
  font-style: normal;
  font-weight: normal;
  src: local('Mordernline'),
    url('./fonts/modernline/modernline.otf') format('opentype');
}

/**
font Majesty Banner  
*/
@font-face {
  font-family: 'Majesti Banner Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Majesti Banner'),
    url('./fonts/majesti-banner/Majesti-Banner-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Majesti Banner Light Italic';
  font-style: italic;
  src: local('Majesti Banner Light Italic'),
    url('./fonts/majesti-banner/Majesti-Banner-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Majesti Banner';
  font-style: normal;
  font-weight: normal;
  src: local('Majesti Banner'),
    url('./fonts/majesti-banner/Majesti-Banner-BookItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Majesti Banner';
  font-style: normal;
  font-weight: bold;
  src: local('Majesti Banner'),
    url('./fonts/majesti-banner/Majesti-Banner-BoldItalic.otf') format('opentype');
}

/**
font Saudagar
*/
@font-face {
  font-family: 'Saudagar';
  font-style: normal;
  font-weight: normal;
  src: local('Saudagar'),
    url('./fonts/saudagar/Saudagar.ttf') format('truetype');
}

/**
font Champagne Limousines
*/
@font-face {
  font-family: 'Champagne Limousines';
  font-style: normal;
  font-weight: normal;
  src: local('Champagne Limousines'),
    url('./fonts/champagne-limousines/Champagne\ &\ Limousines.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Champagne Limousines';
  font-style: italic;
  font-weight: normal;
  src: local('Champagne Limousines'),
    url('./fonts/champagne-limousines/Champagne\ &\ Limousines\ Italic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Champagne Limousines';
  font-style: normal;
  font-weight: bold;
  src: local('Champagne Limousines'),
    url('./fonts/champagne-limousines/Champagne\ &\ Limousines\ Bold.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Champagne Limousines';
  font-style: italic;
  font-weight: bold;
  src: local('Champagne Limousines'),
    url('./fonts/champagne-limousines/Champagne\ &\ Limousines\ Bold\ Italic.ttf')
      format('truetype');
}

/**
CSS RULES
*/

/**
 * Sliders
 */
.swiper-pagination {
  @apply py-2 flex items-center justify-center gap-2 bg-black !bottom-0;
}

.swiper-button-prev,
.swiper-button-next {
  @apply !text-white bg-black rounded hover:!text-gold;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  @apply !text-base;
}

/**
 * SCROLLBAR
 */
html::-webkit-scrollbar,
.modal-content::-webkit-scrollbar {
  width: 5px;
}

html::-webkit-scrollbar-thumb,
.modal-content::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 8px;
}

html::-webkit-scrollbar,
.modal-content::-webkit-scrollbar {
  background: #333;
}

html,
.modal-content {
  scrollbar-color: #666 #333;
  scrollbar-width: thin;
}

/**
 * DOCUMENT
 */

html {
  height: fill-available;
  height: -webkit-fill-available;
}

body,
#root {
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
}

body {
  font-family: 'Majesti Banner Light Italic';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: black;
}

body p{
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Saudagar;
}

.App {
  background-color: black;
  min-height: 100vh;
  color: white;
}

.flex-grow {
  flex-grow: 1;
}

.sr-only,
.hidden {
  display: none;
}

.golden-button {
  padding: 6px 12px;
  color: #ffca00;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  background: #be960066;
}

.phone-row {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-bottom: 20px;
}

.phone-row .form-row:first-child {
  width: 29%;
  position: relative;
}

.phone-row .form-row:last-child {
  margin-top: 0;
  width: 70%;
}

.phone-row .form-row select {
  width: 100%;
  padding-bottom: 0.5rem;
  color: white;
  text-align: center;
  border: none;
  border-bottom: 1px solid #949494;
  background: transparent;
  font-size: 1rem;
  font-family: 'Champagne Limousines';
}

@media (min-width: 1024px) {
  .phone-row .form-row select {
    width: 100%;
    padding-bottom: 0.5rem;
    color: white;
    text-align: center;
    border: none;
    border-bottom: 1px solid #949494;
    font-size: 0.725rem;
  }
}

.phone-row .form-row select:focus {
  outline: none;
  border-bottom: 1px solid white;
}

.phone-row .form-row:last-child input {
  width: 100%;
}

/* SVG */
svg {
  fill: white;
}

/* Link */
a.link,
button.link {
  color: white;
  text-underline-offset: 0.5rem;
  cursor: pointer;
}

/**
SPACER
*/
.spacer {
  width: 10px;
  height: 2px;
}

/**
HOME PAGE
*/

.tabs-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 70vh;
  margin-top: 100px;
  padding-left: 44px;
}

.tab-indicator {
  position: absolute;
  width: 35px;
  margin-left: 14px;
  border-bottom: 1px solid white;
}

.home-page .video {
  width: 100vw;
  height: 100vh;
  position: absolute;
  transform: translateX(-2%);
  overflow: hidden;
}

.home-page .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .tabs-container {
    position: absolute;
    width: 100%;
    height: 90vh;
    top: 50%;
    translate: 0 -50%;
    margin-top: 0;
    padding-left: 36px;
  }
}

/**
TRAVELS PAGE
*/
.travel-page {
  height: 100vh;
  overflow: hidden;
  background-color: #000000;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 10px;
  position: relative;
  z-index: 9;
}

.travel-wrapper {
  height: 100%;
}

.travel-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  padding-inline: 2%;
}

.travel-container h1 {
  font-family: Saudagar, Butler;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 1.25rem;
  margin-left: 5%;
  position: relative;
}

.travel-available-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 1rem;
}

.travel-card {
  width: 32%;
  height: 100%;
  position: relative;
  text-align: center;
}

.travel-card a {
  text-decoration: none;
  color: #fff;
}

.travel-card h2 {
  font-family: Saudagar, Montserrat;
  font-weight: bold;
  font-size: 1.75rem;
  letter-spacing: 0.5px;
  line-height: 2.25rem;
  margin-bottom: 1rem;
}

.travel-card-separator {
  width: 75%;
  height: 2px;
  background: var(--golden-gradient-h);
  clip-path: polygon(0 0%, 0% 100%, 100% 100%);
  margin-top: 5%;
  margin-bottom: 3%;
}

.link-travel-card {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  width: fit-content;
  margin-inline: auto;
}

.link-travel-card .title-separator {
  min-width: inherit;
  width: 60px;
  margin: inherit;
  margin-inline: auto;
  background-size: cover;
}

.travel-card-content-wrapper {
  margin-block: 1.35rem 2rem;
}

.travel-card p {
  font-family: 'Champagne Limousines', Montserrat;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.8px;
}

.sticky{
  position: -webkit-sticky;
position: -moz-sticky;
position: -o-sticky;
position: -ms-sticky;
position: sticky;
}

.sublink{
  font-size: 1.3rem !important;
}

.title-md{
  font-size: 1rem ;
}

.travel-card a.link-to-detail {
  display: block;
  /* position: sticky; */
  top: 100%;
  text-decoration: none;
  color: #595959;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 1rem;
  letter-spacing: 1.4px;
  text-underline-offset: 3px;
  transition: scale 100ms ease;
}

.travel-card a.link-to-detail:hover {
  color: #646464;
  scale: 1.02;
}

.travel-custom-container {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.travel-custom-container a {
  text-decoration: none;
  text-align: center;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 1.25rem;
  letter-spacing: 1.4px;
  color: #595959;
  position: relative;
  transition: letter-spacing 100ms ease;
}

.travel-custom-container a:hover {
  letter-spacing: 1.6px;
}

.travel-custom-container .title-separator {
  width: 60px;
  background-size: cover;
  margin-inline: auto;
}

/**
TRAVEL DETAIL PAGE
*/
.travel-detail-page {
  background-color: black;
}

.travel-detail-container,
.ohter-travel-container {
  min-height: 100vh;
}

.travel-detail-container {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.1) 10%,
    rgba(0, 0, 0, 0.1) 75%,
    rgba(0, 0, 0, 1)
  );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000000;
  position: relative;
  z-index: 10;
}

.travel-detail {
  padding-inline: 15%;
}

.travel-detail-description {
  text-align: center;
  padding-inline: 15%;
  font-family: 'Champagne Limousines', Montserrat;
  font-size: 1rem;
  font-style: italic;
  font-weight: normal;
  line-height: 1.25rem;
  letter-spacing: 1.2px;
  margin-block: 2rem;
}

.travel-detail-description.service-margin {
  margin-top: 1rem;
}

.travel-detail-title {
  text-align: center;
  font-family: Saudagar, Butler;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding-top: 2.5rem;
  padding-bottom: 0.5rem;
}

.title-separator {
  width: 100%;
  height: 2px;
  background: url('./assets/icons/golden-line.svg') no-repeat center center;
  z-index: 1;
}

.title-separator.left {
  background-position: left center;
}

.travel-destination-container {
  display: flex;
  justify-content: center;
  gap: 4%;
  margin-top: 2rem;
}

.travel-detail-page.luxury-body-boost .tabs-menu-travel-container {
  margin-bottom: 4vh;
  flex-direction: row;
  justify-content: center;
  gap: 5vw;
}

.travel-detail-page.french-riviera-getaway .travel-destination-container {
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.travel-detail-page.french-riviera-getaway .tabs-menu-travel-container {
  margin-bottom: 4vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  width: 100%;
}

.travel-destination-container .separator {
  width: 1px;
  background-color: #393939;
}

.ohter-travel-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  padding-block: 2rem;
  text-align: center;
}

.ohter-travel-container h2 {
  width: 100%;
  height: fit-content;
  letter-spacing: 0.6px;
}

.travel-detail-page .link-wrapper.other-station a {
  text-align: center;
  text-decoration: none;
  color: #595959;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.48rem;
  line-height: 0.6rem;
  letter-spacing: 2px;
  position: relative;
  transition-property: scale;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}

.travel-detail-page .link-wrapper.other-station a:hover span {
  color: #646464;
  scale: 1.05;
}

.travel-detail-page .link-wrapper.other-station a:hover {
  letter-spacing: 3px;
}

.travel-detail-page .link-wrapper.other-station a span {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  color: #595959;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.48rem;
  line-height: 1rem;
  letter-spacing: 2px;
  position: relative;
  transition-property: all;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}

.travel-detail-conditions {
  background-color: #000000;
  padding: 0 15% 5%;
  position: relative;
  z-index: 10;
}

.travel-detail-conditions p {
  text-align: center;
  font-family: 'Champagne Limousines', Montserrat;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 1.2px;
  margin-bottom: 5%;
}

.travel-detail-conditions .link-wrapper {
  flex-direction: column;
  align-items: center;
}

.travel-detail-conditions .link-wrapper a {
  text-decoration: none;
  color: #595959;
  font-size: 0.6rem;
  padding-bottom: 0.3rem;
  transition: scale 100ms ease;
}

.travel-detail-conditions .link-wrapper a:hover {
  scale: 1.05;
}

@media (min-width: 1024px) {
  .travel-detail-container {
    padding-bottom: 0;
  }
}

/**
LATEST EXCLUSIVITIES
*/
.exclusivities-page {
  background-color: black;
}

.exclusivities-container {
  background-color: black;
  min-height: 100vh;
}

.exclusivities-container {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
}

.exclusivities-detail {
  padding-left: 15%;
  padding-right: 10%;
  position: relative;
}

.exclusivities-categories-container {
  position: relative;
  margin: -8vh auto 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.exclusivities-categories-container nav {
  margin-left: 7vw;
  min-width: 310px;
}

.exclusivities-categories-container nav .link {
  font-size: 0.875rem;
  text-decoration: underline;
  text-underline-offset: 0.3rem;
  color: white;
}

.exclusivities-categories-container h2 {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  color: #999999;
  font-family: Saudagar, Butler;
  font-size: 1rem;
}

.exclusivities-categories-container h2[data-selected='true'] {
  color: white;
  font-size: 1.4rem;
}

.exclusivities-categories-container .title-separator {
  height: 2px;
  width: 30px;
  background-size: cover;
}

.exclusivities-categories-container h2 a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 1rem;
  color: #595959;
  text-decoration: none;
}

.exclusivities-categories-container > img {
  height: min(85vh, 500px);
  opacity: 1;
}

/**
LATEST EXCLUSIVITIES & ADVENTURES DETAIL PAGE
*/
.exclusivities-detail-page {
  background-color: #000000;
  height: 100vh;
}

.exclusivities-detail-title {
  padding-top: 5vh;
  font-size: 1.5rem;
}

.exclusivities-detail-content {
  position: relative;
  min-height: 100vh;
}

.exclusivities-detail-carousel {
  display: flex;
  align-items: center;
  gap: 3%;
  height: 70vh;
  padding-left: 6%;
  padding-right: 3%;
  padding-top: 1rem;
}

@media (min-width: 1024px) {
  .exclusivities-detail-title {
    padding-top: 1rem;
    font-size: 1.5rem;
  }

  .exclusivities-categories-container {
    justify-content: space-between;
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    padding-inline: 15%;
  }

  .exclusivities-categories-container h2 {
    margin: 1rem 0;
  }

  .exclusivities-categories-container nav {
    margin: 0;
  }

  .exclusivities-categories-container > img {
    height: max(60vh, 500px);
    opacity: 1;
  }
}

/**
ABOUT OUR CLUB PAGE
*/
.about-our-club-page {
  padding-top: 1.3rem;
  height: 100vh;
}

.about-our-club-page .content {
  margin: 0 auto;
  max-width: min(80vw, 900px);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.about-our-club-page .content > section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.about-our-club-page .about-our-club-title {
  position: fixed;
  width: 100%;
  text-align: center;
}

.about-our-club-page h1 {
  font-family: Saudagar, Butler;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
}

.about-our-club-page .title-separator {
  margin-bottom: 0.5rem;
}

.about-our-club-page .content section {
  text-align: center;
}

.about-our-club-page .what-we-do p {
  margin-block: 1rem;
  font-family: 'Champagne Limousines';
}

.about-our-club-page .what-we-do p:first-child {
  margin-top: 0;
}

.about-our-club-page .who-we-are {
  position: relative;
  width: min(50vw, 1000px);
  margin-inline: auto;
  display: flex;
  gap: 10vw;
  justify-content: center;
  align-items: center;
}

.about-our-club-page .who-we-are img {
  height: 60vh;
  border-radius: 1rem;
  filter: grayscale(1);
}

.about-our-club-page .who-we-are img.selected {
  filter: grayscale(0) !important;
}

.about-our-club-page .who-we-are p {
  position: absolute;
  left: 0;
  width: 100%;
  font-family: 'Champagne Limousines';
}

.about-our-club-page .who-we-are .image-section p {
  position: relative;
  max-width: inherit;
  margin-top: 0.2rem;
  font-size: 1rem;
  text-align: center;
}

.about-our-club-page .who-we-are p.sebastien {
  left: 50%;
  translate: -50% 0;
}

.about-our-club-page .who-we-are button {
  display: block;
  margin: 5vh auto 0;
  color: white;
  font-size: 0.6rem;
  text-decoration: underline;
  border: none;
  background: none;
}

.about-our-club-page .scrollbar-container {
  position: absolute;
  right: 2vw;
  top: 25%;
  height: 50vh;
}

.about-our-club-page .this-are-our-partner p {
  font-size: 1rem;
  text-align: center;
  font-family: 'Champagne Limousines';
  margin-bottom: 5rem;
}

.about-our-club-page .this-are-our-partner .link-wrapper {
  flex-direction: column;
  align-items: center;
}

.about-our-club-page .this-are-our-partner .link-wrapper a {
  text-decoration: none;
  color: #595959;
  font-size: 0.725rem;
  padding-bottom: 0.25rem;
}

.about-our-club-page .this-are-our-partner .link-wrapper .title-separator {
  width: 3rem;
  background-size: cover;
}

.about-our-club-page .this-are-our-partner .link-wrapper a:hover {
  scale: 1.05;
}

/**
CONTACT PAGE
*/
.contact-page {
  min-height: 100vh;
  background-color: black;
}

.contact-content-container {
  padding-top: 1.3rem;
  padding-bottom: 3.5rem;
}

.contact-title-container {
  width: 75%;
  margin-inline: auto;
  text-align: center;
}

.contact-title-container .title-separator {
  margin-left: 1rem;
}

@media (min-width: 1024px) {
  .contact-title-container .title-separator {
    margin-left: 0;
  }
}

.contact-title-container h1 {
  font-family: Saudagar, Butler;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
}

.contact-content-greeting {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.contact-title-destination {
  padding-bottom: 0;
  font-family: Saudagar, Butler;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0px;
  color: var(--golden-text);
}

.contact-content-greeting-description + * {
  margin-top: 0.5rem;
}

.contact-content-greeting-description > span {
  font-family: 'Champagne Limousines';
  padding-bottom: 1rem;
}

.contact-content-greeting-description,
.contact-content-greeting-description .service-highlight {
  letter-spacing: 1.2px;
  font-size: 0.8rem;
  color: #ffffff;
}

.contact-content-greeting .service-highlight {
  font-style: italic;
  margin-bottom: 3rem;
  font-size: 0.9rem;
  color: var(--golden-text);
}

.contact-content-form-container {
  width: 60%;
  margin-inline: auto;
}

.contact-content-form-container form {
  font-family: 'Champagne Limousines', Montserrat;
}

.contact-content-form-container .form-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3rem;
}

.contact-content-form-container form label {
  display: block;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
}

.contact-content-form-container form textarea {
  width: 100%;
  height: 100%;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-family: 'Champagne Limousines', Montserrat;
  font-size: 1rem;
  border: none;
  border-bottom: 0.5px solid #bfbfbf;
  background: transparent;
  color: white;
}

.contact-content-form-container form textarea:focus {
  outline: none;
  border-bottom: 1px solid white;
}

.contact-content-form-container form input {
  width: 100%;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  border: inherit;
  border-bottom: 0.5px solid #bfbfbf;
  background: transparent;
  font-family: 'Champagne Limousines', Montserrat;
  font-size: 1rem;
  color: white;
}

.contact-content-form-container form input:focus {
  outline: none;
  border-bottom: 1px solid white;
}

::placeholder {
  letter-spacing: 0.8px;
  color: #949494;
}

.contact-content-form-container .phone-row .form-row select {
  font-family: 'Champagne Limousines', Montserrat;
  font-size: 1rem;
}

.contact-content-form-container p.small-text {
  font-family: 'Champagne Limousines', Montserrat;
  font-size: 0.725rem;
  font-weight: 300;
  letter-spacing: 0.64px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.contact-content-whatsapp-container {
  margin: 0;
  padding: 0;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.contact-content-whatsapp-container a {
  text-decoration: none;
  color: white;
}

.contact-content-whatsapp-container p {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 0.8rem;
  border-bottom: 1px solid white;
  padding-bottom: 0.1rem;
}

/* photo duo */
.contact-content-intro {
  display: none;
  width: 30%;
  overflow: hidden;
  padding-bottom: 1.5rem;
}

.contact-content-intro img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.swal2-actions button.swal2-confirm.swal2-styled {
  background-color: var(--golden-text);
  padding: 5px 10px;
  border-radius: 0;
  font-family: 'Champagne Limousines', Montserrat;
  font-size: 0.8125rem;
}

.swal2-actions button.swal2-confirm.swal2-styled:focus,
.swal2-actions button.swal2-confirm.swal2-styled:active {
  outline: none;
  box-shadow: none;
}

.swal2-title {
  font-family: 'Champagne Limousines', Montserrat;
  font-size: 1.15rem;
}

.swal2-html-container {
  font-family: 'Champagne Limousines', Montserrat;
  font-size: 0.8125rem;
}

/**
LOGIN PAGE
*/
.login-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding-top: 2%;
  padding-bottom: 2%;
  background-image: url('./assets/images/bg-login.png') center center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.login-section .logo {
  position: absolute;
  width: max(35vh, 35vw);
  z-index: 10;
}

.login-section .sentences {
  position: absolute;
  text-align: center;
  font-family: 'Mordernline';
  font-size: 2rem;
  line-height: 3rem;
  word-spacing: 2ch;

  /* Blur effect */
  color: transparent;
  text-shadow: 0 0 5px rgb(255, 255, 255);
}

.sentences > p {
  font-family: Mordernline;
}

.login-section .access-code {
  padding: 0.5rem 1rem;
  color: white;
  font-size: 0.9rem;
  border: none;
  border-radius: 20px;
  background-color: #45454366;
  position: absolute;
  top: 66%;
  left: 50%;
  translate: -50% -50%;
  font-family: 'Champagne Limousines';
  text-transform: uppercase;
}

.login-section .about-this-club-link-wrapper {
  text-align: center;
  margin-bottom: 4rem;
}

.login-section .about-this-club-link-wrapper a {
  font-family: 'Champagne Limousines', Montserrat;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 1px;
  text-decoration: none;
  color: #595959;
}

.login-section .about-this-club-link-wrapper a:hover {
  color: white;
  font-weight: 400;
}

.login-section .keypad-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 25vh;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Majesti Banner', 'Champagne Limousines', Montserrat;
  background: #00000033;
  padding-bottom: min(38vh, 45vw);
}

@media (min-width: 1200px) {
  .login-section .keypad-wrapper {
    padding-bottom: 15vh;
  }
}

.login-section .login-keypad {
  margin: 1rem 0 1.2rem 0;
  padding-bottom: 30px;
}

.login-section .code {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.5rem;
  font-family: 'Majesti Banner';
}

/**
PORTRAIT MODE
*/
main.portrait {
  width: 100vw;
  height: 100vh;
}

@media (display-mode: standalone) or (display-mode: fullscreen) or (max-height: 512px) {
  main.portrait {
    position: absolute;
    top: calc((100vw - 100vh) / 2 * -1);
    left: calc((100vw - 100vh) / 2);
    width: 100vh;
    height: 100vw;
    transform: rotate(270deg);
  }
}

@media (min-width: 1200px) {
  main.portrait.about-this-club-page {
    height: inherit;
    min-height: 100vh;
  }
}

.portrait .login-section {
  width: 100%;
  height: 100%;
}

.portrait .login-section .logo {
  top: min(25vh, 22vw);
}

.portrait .login-section .angel {
  position: absolute;
  width: 35%;
  max-width: 250px;
  transform: translate(0, 22vh);
}

.instagram {
  width: 29px;
  margin: auto;
}

.dashboard h1 {
  position: absolute;
  top: 69%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-family: 'majesti banner regular';
  font-size: 5.5rem;
  letter-spacing: 27px;
  left: 50%;
  opacity: 0.50;
}

@media (max-width: 1024px) {
  .dashboard h1 {
    top: 42%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    letter-spacing: 15px;
    left: 50%;
    opacity: 0.70;
  }
}

.dashboard .video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
}
.dashboard  .video-container video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%,-50%);
}


.dashboard>div{
 background-size: contain;
  background-repeat: no-repeat;
}


@media (display-mode: standalone) or (display-mode: fullscreen) or (max-height: 512px) {
  .login-page {
    overflow: hidden;
  }

  .portrait .login-section .angel {
    width: 35%;
    transform: translate(0, 25vw);
  }
}

.portrait .login-section .sentences {
  top: max(50vw, 50vh);
}

.portrait .login-section .keypad-wrapper,
.portrait .video-page .keypad-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 65vh;
}

@media (display-mode: standalone) or (display-mode: fullscreen) or (max-height: 512px) {
  .portrait .login-section .keypad-wrapper {
    padding-top: 45vw;
  }
}

.portrait .login-section .keypad-wrapper.with-keypad {
  padding-top: 0;  
}

.portrait .login-section .about-this-club-link-wrapper {
  position: absolute;
  bottom: 2vw;
}

@media (min-width: 1024px) {
  .portrait .login-section .logo {
    top: 3rem;
  }

  .login-section .logo {
    width: 350px;
  }

  .portrait .login-section .angel {
    position: absolute;
    width: 250px;
    transform: translate(0, 20vh);
  }

  .portrait .login-section .sentences {
    top: 60%;
    translate: 0 -50%;
  }

  .login-section .about-this-club-link-wrapper {
    margin-bottom: 0rem;
  }

  .login-section .access-code {
    bottom: 4rem;
    cursor: pointer;
  }
}

@media (min-width: 1200px) {
  .portrait .login-section .logo {
    top: 4rem;
  }

  .login-section .logo {
    width: 500px;
  }

  .portrait .login-section .angel {
    display: none;
  }

  .portrait .login-section .sentences {
    display: none;
  }

  .login-section .access-code {
    text-decoration: underline;
    text-underline-offset: 0.3rem;
    bottom: 4rem;
  }
}

/**
ABOUT THIS CLUB PAGE
*/
.about-this-club-page {
  min-height: 100vh;
  padding: 10vh 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: black;
}

.about-this-club-page h1 {
  font-family: Saudagar, Butler;
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: 0.3rem;
}

.about-this-club-page p {
  font-family: 'Champagne Limousines', Montserrat;
  font-size: 1.05rem;
  line-height: 1.25rem;
  letter-spacing: 0.5px;
}

.about-this-club-page .links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
  margin-inline: auto;
}

.about-this-club-page .links a {
  padding: 0.5rem 1rem;
  color: white;
  font-size: 0.825rem;
  text-decoration: none;
  border-radius: 2rem;
  background-color: #454543;
}

.about-this-club-page .btn-back-container,
.register-page .btn-back-container {
  top: 95vh;
  left: 1rem;
  cursor: pointer;
}

.about-this-club-page .btn-back-container p {
  font-size: inherit;
}

@media (orientation: landscape) {
  .about-this-club-page .btn-back-container,
  .register-page .btn-back-container {
    top: 95vh;
    left: 1rem;
    cursor: pointer;
  }
}

@media (min-width: 1024px) {
  .about-this-club-page .btn-back-container,
  .register-page .btn-back-container {
    top: 95vh;
  }

  .about-this-club-page .links a {
    font-size: 0.5rem;
  }
}

/**
REGISTER PAGE
*/
.register-page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
  padding-inline: 5%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('./assets/images/connexion.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (orientation: landscape) {
  .register-page {
    padding-top: 5%;
    padding-bottom: 2%;
  }

  .register-img-wrapper {
    width: min(60%, 1000px);
  }

  .register-form-container form .phone-row {
    margin-inline: auto;
  }

  .register-form-container form .form-checkbox-row label {
    text-align: right;
    width: fit-content;
  }

  .register-form-container form .form-checkbox-row input {
    text-align: left;
    width: fit-content;
    font-family: 'Champagne Limousines';
  }

  .register-form-container form textarea {
    height: 20vh;
    font-family: 'Champagne Limousines';
  }
}

@media (orientation: portrait) {
  .register-page {
    padding-top: 8%;
    padding-bottom: 5%;
  }

  .register-img-wrapper {
    width: 55% !important;
  }

  .register-form-container form {
    margin-top: 5%;
  }

  .register-form-container form input {
    width: 100%;
    font-family: 'Champagne Limousines';
  }

  .register-form-container form .form-checkbox-row label {
    width: 95%;
    font-family: 'Champagne Limousines';
  }

  .register-form-container form .form-checkbox-row input {
    width: 5%;
    font-family: 'Champagne Limousines';
  }

  .register-form-container form textarea {
    height: 10vh;
    width: 100%;
    font-family: 'Champagne Limousines';
  }
}

.register-img-wrapper {
  width: 40%;
  margin-bottom: 2rem;
}

.register-img-wrapper img {
  width: 100%;
  height: auto;
}

.register-form-container {
  flex-grow: 1;
}

.register-form-container form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  text-align: center;
}

.register-form-container .form-row,
.register-form-container .phone-row,
.register-form-container input,
.register-form-container textarea {
  width: 100%;
  font-size: 1rem;
  font-family: 'Champagne Limousines';
}

@media (min-width: 1024px) {
  .register-form-container .form-row,
  .register-form-container .phone-row,
  .register-form-container input,
  .register-form-container textarea {
    width: 100%;
    font-size: 0.725rem;
  }
}

.register-form-container form label {
  display: none;
}

.register-form-container form .form-checkbox-row label {
  display: block;
  font-family: 'Champagne Limousines', Montserrat;
  font-size: 0.725rem;
  line-height: 1rem;
  letter-spacing: 0.4px;
}

.register-form-container form .form-checkbox-row {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 0.75rem;
}

.register-form-container form textarea {
  border: 0.5px solid #bfbfbf;
  background: transparent;
  padding: 2%;
  color: #949494;
  font-family: 'Champagne Limousines';
}

.register-form-container form textarea:focus {
  outline: none;
  border: 1px solid white;
}

.register-form-container form input {
  padding: 3px 3px 8px;
  border: inherit;
  border-bottom: 0.5px solid #949494;
  background: transparent;
  text-align: center;
  color: white;
  font-family: 'Champagne Limousines';
}

.register-form-container form input:focus {
  outline: none;
  border-bottom: 1px solid white;
}

::placeholder {
  letter-spacing: 0.8px;
  color: #949494;
}

.register-form-container form button {
  padding-bottom: 5px;
  border: none;
  border-bottom: 1px solid white;
  background-color: transparent;
  letter-spacing: 2px;
  color: white;
}

.register-form-container form button:hover,
.register-form-container form button:active {
  border-bottom: 1px solid white;
  background-color: transparent;
  color: white;
  letter-spacing: 0.15em;
}

.register-form-container form button:focus {
  outline: none;
}

@media (min-width: 1024px) {
  .register-page {
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .register-img-wrapper {
    width: min(60%, 400px);
  }

  .register-form-container {
    margin-top: 3rem;
  }
}

/**
GREETING PAGE
*/
.greeting-page {
  height: 100vh;
  padding: 0 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6vh;
  font-family: Saudagar, 'Champagne Limousines', Montserrat;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.35rem;
  letter-spacing: 1.8px;
  background: #000000;
}

.greeting-page p {
  font-family: Saudagar, 'Champagne Limousines', Montserrat;
  font-size: 1.05rem;
}

.greeting-page button {
  margin-top: 4vh;
  color: white;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 1rem;
  letter-spacing: 2px;
  border: none;
  border-bottom: 1px solid white;
  background-color: transparent;
  cursor: pointer;
  transition: scale 250ms ease;
}

.greeting-page button:hover {
  scale: 1.1;
}

/**
INTRO VIDEO PAGE
*/
.video-page {
  height: 100vh;
  background-color: #000000;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.video-page video {
  width: 175%;
  margin-top: -25%;
}

@media (min-width: 768px) and (orientation: portrait) {
  .video-page video {
    width: 100%;
    margin-top: -50%;
  }

  .video-page .desktop-login-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
  }
  .portrait .login-section .logo {
    top: 10vh;
  }

  .video-page .desktop-login-wrapper .keypad-wrapper.with-keypad,
  .login-page .login-section .keypad-wrapper.with-keypad {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    margin-bottom: 15vh;
  }

  .login-page .login-section .keypad-wrapper {
    justify-content: flex-end !important;
    gap: 0.5rem !important;
    padding-bottom: 15vh;
  }

  .video-page .desktop-login-wrapper .keypad-wrapper.with-keypad .interaction,
  .login-page .login-section .keypad-wrapper.with-keypad {
    font-size: 1.05rem;
    padding-bottom: 33px;
  }

  .video-page .desktop-login-wrapper .keypad-wrapper.with-keypad .code,
  .login-page .login-section .keypad-wrapper.with-keypad .code {
    font-size: 1.5rem;
    font-family: 'majesti banner';
  }

  .video-page .access-code,
  .login-page .login-section .access-code {
    position: absolute;
    left: 50%;
    translate: -50% 0;
    bottom: 20vh;
    background-color: transparent;
    border: none;
    text-decoration: none;
    text-underline-offset: 0.3rem;
    text-transform: uppercase;
    color: white;
    font-size: 1rem;
    font-family: 'Champagne Limousines';
    animation: fadeIn 9.5s;
    cursor: pointer;
    transition: scale 100ms ease;
  }

  .login-page .login-section .access-code {
    bottom: 2rem;
    padding: 0;
  }

  .video-page .access-code:hover {
    transform: scale(1.05);
  }

  .video-page .about-this-club-link-wrapper a {
    color: #595959;
    position: absolute;
    top: 90vh;
    text-decoration: none;
    animation: fadeIn 10s;
  }

  .video-page .about-this-club-link-wrapper a:hover {
    color: #ffffff;
  }
}

.font-normal{
  font-style: normal;
}

.seeking-button button{
  text-decoration: none;
}

@media (min-width: 1024px) {
  .video-page video {
    width: 85%;
    margin-top: -35%;
  }

  .video-page .desktop-login-wrapper,
  .login-page .login-section .keypad-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: fcenter;
    align-items: flex-end;
    text-align: center;
  }
  .login-page .login-section .keypad-wrapper {
    padding-top: 0;
  }

  .video-page .desktop-login-wrapper .keypad-wrapper.with-keypad,
  .login-page .login-section .keypad-wrapper.with-keypad {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    margin-bottom: 15vh;
  }
  .login-page .login-section .keypad-wrapper.with-keypad {
    gap: 0.3rem;
    padding-bottom: 10vh;
  }

  .video-page .desktop-login-wrapper .keypad-wrapper.with-keypad .interaction,
  .login-page .login-section .keypad-wrapper.with-keypad .interaction {
    font-size: 1.05rem;
    padding-bottom: 33px;
  }
  .login-page .login-section .keypad-wrapper.with-keypad .interaction {
    text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
  }

  .video-page .desktop-login-wrapper .keypad-wrapper.with-keypad .code {
    font-size: 1.5rem;
    font-family: 'majesti banner';
  }
  .login-page .login-section .keypad-wrapper.with-keypad .code {
    font-size: 1.3rem;
    font-family: 'majesti banner';
  }
  .login-page .login-section .keypad-wrapper.with-keypad .key {
    font-size: 1rem;
  }

  .video-page .access-code,
  .login-page .login-section .access-code {
    position: absolute;
    left: 50%;
    translate: -50% 0;
    bottom: 4rem;
    background-color: transparent;
    border: none;
    text-decoration: none;
    text-underline-offset: 0.3rem;
    text-transform: uppercase;
    color: white;
    font-size: 1rem;
    font-family: 'Champagne Limousines';
    animation: fadeIn 9.5s;
    cursor: pointer;
    transition: scale 100ms ease;
  }
  .login-page .login-section .access-code {
    bottom: 0.1rem;
    padding: 0;
  }

  .video-page .access-code:hover {
    transform: scale(1.05);
  }

  .video-page .about-this-club-link-wrapper a {
    color: #595959;
    position: absolute;
    top: 90vh;
    text-decoration: none;
    animation: fadeIn 10s;
  }

  .video-page .about-this-club-link-wrapper a:hover {
    color: #ffffff;
  }
}

@media (min-width: 1200px) {
  .video-page video {
    width: 85%;
    margin-top: -30%;
  }
}

.video-page a {
  position: absolute;
  top: 80vh;
  left: 50%;
  color: #ffffff;
  text-decoration: underline;
  text-underline-offset: 0.5rem;
  font-family: 'Champagne Limousines', Montserrat;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  letter-spacing: 2.4px;
  transform: translate(-50%, 0);
  animation: fadeIn 9.5s;
  cursor: pointer;
}

@media (display-mode: standalone) or (display-mode: fullscreen) or (max-height: 512px) {
  .video-page a {
    top: 80vw;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/**
BOOK PAGE
*/
.book-page {
  height: 100vh;
  background-image: url('./assets/images/bg-book.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.book-page-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.book-page-img img {
  width: 55vw;
  height: auto;
}

.book-overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgb(0, 0, 0);
  opacity: 0.6;
  z-index: 4;
  animation: fadeInBook 1.5s;
}

@keyframes fadeInBook {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
}

.book-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-inline: 3%;
  gap: 20px;
  position: relative;
  z-index: 5;
}

.book-previous-btn-container,
.book-next-btn-container {
  width: 100px;
  text-align: center;
}

.book-previous-btn-container button,
.book-next-btn-container button {
  font-family: 'Champagne Limousines';
  font-weight: bold;
  background-color: transparent;
  border: none;
  border-top: 1px solid var(--golden-text);
  border-bottom: 1px solid var(--golden-text);
  color: white;
  letter-spacing: 2.2px;
}

.book-item {
  width: 75%;
  text-align: center;
  margin-inline: auto;
}

.book-item.active-item {
  height: 65%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.title-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 2rem;
}

.title-marker {
  width: 40px;
  height: 2px;
  background-color: white;
}

.book-item {
  animation: fadeInChap 250ms ease-in-out;
}

@keyframes fadeInChap {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.title-row h1 {
  font-family: Saudagar, Butler;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.book-item p {
  font-family: 'Champagne Limousines', Montserrat;
  font-weight: 300;
  font-size: 1.15rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  max-width: 1400px;
  margin-inline: auto;
}

.book-item a {
  text-decoration: underline;
  text-underline-offset: 0.5rem;
  color: white;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 1rem;
}

.book-item a:hover {
  font-weight: 500;
}

.book-content.fade-in-text,
.book-progressbar.fade-in-text {
  animation: fadeInBlock 1.5s;
}

@keyframes fadeInBlock {
  0% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.book-progressbar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding-inline: 3%;
  position: absolute;
  bottom: 3rem;
  z-index: 5;
}

.book-progressbar-wrapper {
  width: calc(100% - 200px);
  display: flex;
  align-items: center;
}

.book-progressbar-item {
  flex: 1;
  height: 1px;
  background-color: #707070;
}

.book-progressbar-item.active {
  height: 1px;
  background: var(--golden-gradient-h);
}

.font-canto-roman {
  font-family: 'Canto';
}

.title-sm {
  font-size: 0.775rem;
}

@media (max-width: 1024px) {
  .title-sm {
    font-size: 0.875rem;
  }
}

button{
text-decoration: underline;
text-underline-offset: 6px;
text-decoration-color: #C6A97C;
}