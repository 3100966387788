/**
BUTTON NAV
*/
.menu-main-button-wrapper {
    width: 60px;
    position: absolute;
    top: 20px;
    right: 1rem;
    cursor: pointer;
    z-index: 51;
}

.menu-main-button-container-bar,
.menu-main-button-container {
    display: flex;
}

.menu-main-button-container-bar {
    justify-content: end;
    margin-block: 4px;
}

.menu-main-button-container {
    justify-content: flex-end;
    align-items: center;
    gap: 2px;
    width: 60px;
}

.menu-main-button-name {
    color: white;
    font-size: .5rem;
    letter-spacing: 1px;
    transition-property: all;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.menu-main-button-bar {
    width: 18px;
    height: 2px;
    background-color: white;
    margin: 5px 0;
    border-radius: 1px;
    transition-property: all;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    animation-fill-mode: backwards;
}

.menu-main-button-bar-gold {
    width: 18px;
    height: 1px;
    /* transform: matrix(-1, 0, 0, -1, 0, 0); */
    background: transparent var(--golden-gradient-h) 0% 0% no-repeat padding-box;
    transition-property: all;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@media (hover: hover) {
    .menu-main-button-wrapper:hover .menu-main-button-bar:first-child {
        transform-origin: 18px 30px;
        transform: translate(-30px, -30px) rotate(180deg);
    }

    .menu-main-button-wrapper:hover .menu-main-button-bar:last-child {
        transform-origin: 18px 30px;
        transform: translate(-6px, -30px) rotate(180deg);
    }

    .menu-main-button-wrapper:hover .menu-main-button-bar.inverse:first-child {
        transform-origin: 0 -30px;
        transform: translate(6px, 34px) rotate(180deg);
    }

    .menu-main-button-wrapper:hover .menu-main-button-bar.inverse:last-child {
        transform-origin: 0 -30px;
        transform: translate(30px, 34px) rotate(180deg);
    }

    .menu-main-button-wrapper:hover .menu-main-button-bar-gold {
        width: 12px;
        transform: translateX(-100%);
    }

    .menu-main-button-wrapper:hover .menu-main-button-name {
        letter-spacing: 4px;
        transform: translateX(-90%);
    }
}

/**
BUTTON CLOSE MENU
*/
.menu-main-button-wrapper.button-close {
    z-index: 51;
}

.menu-main-button-wrapper.button-close .menu-main-button-container {
    margin-block: 5px;
}

.menu-main-button-wrapper.button-close .menu-main-button-name {
    display: none;
}

.menu-main-button-wrapper.button-close .menu-main-button-bar-gold {
    width: 12px;
    margin-right: 12px;
}

.menu-main-button-wrapper.button-close .menu-main-button-bar:first-child {
    transform-origin: 18px 30px;
    transform: translate(-57px, -13px) rotate(135deg);
}

.menu-main-button-wrapper.button-close .menu-main-button-bar:last-child {
    transform-origin: 18px 30px;
    transform: translate(-2px, -26px) rotate(225deg);
}

.menu-main-button-wrapper.button-close .menu-main-button-bar.inverse:first-child {
    transform-origin: 0 -30px;
    transform: translate(-29px, 30px) rotate(225deg);
}

.menu-main-button-wrapper.button-close .menu-main-button-bar.inverse:last-child {
    transform-origin: 0 -30px;
    transform: translate(32px, 17px) rotate(135deg);
}

.menu-main-button-wrapper.button-close .menu-main-button-bar-gold {
    width: 12px;
    transform: translateX(-115%) rotate(90deg);
}

@media (min-width: 1024px) {
    .menu-main-button-wrapper {
        top: .5rem;
        right: 1rem;
        cursor: pointer;
        z-index: 51;
    }
    .menu-main-button-bar {
        width: 22px;
        height: 3px;
        margin: 8px 0;
    }
    
    .menu-main-button-bar-gold {
        width: 22px;
        height: 2px;
    }
    .menu-main-button-wrapper:hover .menu-main-button-bar:first-child {
        transform-origin: 18px 30px;
        transform: translate(-30px, -25px) rotate(180deg);
    }

    .menu-main-button-wrapper:hover .menu-main-button-bar:last-child {
        transform-origin: 18px 30px;
        transform: translate(-6px, -25px) rotate(180deg);
    }

    .menu-main-button-wrapper:hover .menu-main-button-bar.inverse:first-child {
        transform-origin: 0 -30px;
        transform: translate(6px, 30px) rotate(180deg);
    }

    .menu-main-button-wrapper:hover .menu-main-button-bar.inverse:last-child {
        transform-origin: 0 -30px;
        transform: translate(30px, 30px) rotate(180deg);
    }

    .menu-main-button-wrapper:hover .menu-main-button-bar-gold {
        width: 16px;
        transform: translateX(-100%);
    }
    .menu-main-button-wrapper.button-close .menu-main-button-bar:first-child {
        transform-origin: 18px 30px;
        transform: translate(-57px, -8px) rotate(135deg);
    }
    
    .menu-main-button-wrapper.button-close .menu-main-button-bar:last-child {
        transform-origin: 18px 30px;
        transform: translate(2px, -19px) rotate(225deg);
    }
    
    .menu-main-button-wrapper.button-close .menu-main-button-bar.inverse:first-child {
        transform-origin: 0 -30px;
        transform: translate(-29px, 25px) rotate(225deg);
    }
    
    .menu-main-button-wrapper.button-close .menu-main-button-bar.inverse:last-child {
        transform-origin: 0 -30px;
        transform: translate(35px, 10px) rotate(135deg);
    }
    
    .menu-main-button-wrapper.button-close .menu-main-button-bar-gold {
        width: 16px;
        transform: translateX(-110%) rotate(90deg);
    }
}